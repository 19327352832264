"use client";

import { useEffect } from "react";
import TailwindAdvancedEditor from "@/components/tailwind/advanced-editor";
import { Button } from "@/components/tailwind/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/tailwind/ui/dialog";
import Menu from "@/components/tailwind/ui/menu";
import { ScrollArea } from "@/components/tailwind/ui/scroll-area";
import { BookOpen, GithubIcon } from "lucide-react";
import Link from "next/link";

export default function Page() {
  // useEffect(() => {
  //   const script1 = document.createElement("script");
  //   script1.innerHTML = `
  //     window.difyChatbotConfig = {
  //       token: '5R3b7Fp5fTIfonKu'
  //     }
  //   `;
  //   document.head.appendChild(script1);

  //   const script2 = document.createElement("script");
  //   script2.src = "https://udify.app/embed.min.js";
  //   script2.id = "5R3b7Fp5fTIfonKu";
  //   script2.defer = true;
  //   document.head.appendChild(script2);

  //   const style = document.createElement("style");
  //   style.innerHTML = `
  //     #dify-chatbot-bubble-button {
  //       background-color: #b6d7a8 !important;
  //     }
  //     #dify-chatbot-bubble-window {
  //       width: 24rem !important;
  //       height: 40rem !important;
  //     }
  //   `;
  //   document.head.appendChild(style);

  //   return () => {
  //     document.head.removeChild(script1);
  //     document.head.removeChild(script2);
  //     document.head.removeChild(style);
  //   };
  // }, []);

  return (
    <div className="flex min-h-screen flex-col items-center gap-4 py-4 sm:px-5">
      <div className="flex absolute right-5 top-5 z-10 ">
        <Menu />
      </div>
      <TailwindAdvancedEditor />
    </div>
  );
}